<template>
    <div>
        <router-link class="card text-dark text-decoration-none rounded-xl shadow border-0"
                     v-bind:class="{ 'is-selected': isSelected, 'is-reordering': isReordering }"
                     v-bind:to="{ 'path': `/groups/${ id }`, 'query': $route.query }">
            <div class="card-body p-2">
                <div class="d-flex">
                    <div class="col-auto d-flex align-items-center">
                        <b-avatar class="shadow-sm border-0"
                                  size="lg"
                                  v-bind:src="avatar">
                            <h4 class="mb-0">
                                <font-awesome-icon v-bind:icon="['fad', 'user-group-crown']"></font-awesome-icon>
                            </h4>
                        </b-avatar>
                    </div>
                    <div class="flex-grow-1 d-flex flex-column justify-content-center">
                        <h5 class="font-weight-bolder mb-1">
                            {{ name }}
                        </h5>
                        <p class="mb-0"
                           v-if="leader">
                            led by <strong>{{ leader }}</strong>
                        </p>
                    </div>
                    <div class="col-auto d-flex">
                        <div class="col-6 col-md-4 col-lg mb-2 mb-md-0">
                            <h2 class="text-center font-weight-bold text-nowrap mb-0">
                                NA<small style="font-size: 50%; ">/255</small>
                            </h2>
                            <p class="text-center text-nowrap mb-1">
                                👨‍👩‍👦 Members
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import { apiUrl, apiVersion, } from "@/config";
import { BAvatar, } from "bootstrap-vue";
export default {
    name: "GroupItem",
    components: {
        BAvatar,
    },
    props: {
        group: {
            type: Object,
        },
        isReordering: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        id () {
            return this.group?._id ?? "";
        },
        isSelected () {
            return this.id === this.$route.params.groupId;
        },
        chat () {
            return this.$store.getters["whatsapp/chatById"](this.id);
        },
        avatar () {
            return "";  /*`${ apiUrl }/${ apiVersion }/activity`*/
        },
        name () {
            return this.group?.name ?? "";
        },
        leader () {
            if (this.group?.leaderUser) {
                const target = this.$store.getters["users/itemById"](this.group.leaderUser);
                if (target) {
                    return `${ target?.name } (+${ target.countryCode } ${ target.phone })`;
                }
            }
            return this.group?.leader ?? "";
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.card {
    transition:
        transform 150ms ease-in-out,
        box-shadow 200ms ease-in-out;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.08);

    &:not(.is-reordering) {
        &:hover,
        &:active,
        &.is-selected {
            transform: scale(1.02);
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        }
    }

    &.is-reordering {
        cursor: move;
    }
}
</style>
